import React from 'react';
import { LandingSearchForm } from '../../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { isOriginInUse } from '../../../../util/search';
import { useConfiguration } from '../../../../context/configurationContext';
import { locationBounds } from '../../../../components/LocationAutocompleteInput/GeocoderMapbox';

import css from './SectionHero.module.css';

function RenderLandingPageSectionHero() {
  const history = useHistory();
  const config = useConfiguration();

  const handleSubmit = values => {
    const { profileAddress, addressRadius } = values;
    const { search, selectedPlace = {} } = profileAddress || {};
    const { origin, bounds } = selectedPlace;
    const originMaybe = isOriginInUse(config) ? { origin } : {};

    const distanceInMeters = addressRadius * 1000;
    const newBounds = addressRadius && locationBounds(origin, distanceInMeters);
    const searchParams = {
      ...originMaybe,
      address: search,
      bounds: addressRadius ? newBounds : bounds,
      radius: addressRadius,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return <LandingSearchForm rootClassName={css.landingSearchForm} onSubmit={handleSubmit} />;
}

export default RenderLandingPageSectionHero;
